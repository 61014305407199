export default {
  methods: {
    getQid(obj) {
      let firstLetter = this.qidType(obj)[0].toUpperCase()
      return `${firstLetter}${obj.asset_id}`
    },
    qidType(obj) {
      if (obj.asset_type == 'V3Flashcard' || obj.asset_type == 'Answer') {
        return 'Item'
      } else if (obj.asset_type == 'ModuleSection') {
        return "Learning Module"
      } else {
        return obj.asset_type
      }
    },
    qidRoute(obj) {
      return {
        name: this.apiToCmsType(obj.type),
        params: { id: obj.id }
      }
    },
    apiToCmsType (apiType) {
      const option = {
        Attachment: 'attachment',
        Topic: 'topic',
        V3Flashcard: 'item',
        LearningModule: 'learning_module',
        Grouping: 'grouping'
      }
      return option[apiType]
    }
  }
}
