<template>
  <div
    v-if="association._destroy !== true"
    class="product-association-row"
  >
    <div
      class="product-association-column pa-main-column"
      :class="{'wide-column': hideCategories}"
    >
      <hlt-multiselect
        v-if="availableApps"
        ref="multiselect1"
        v-model="selectedApp"
        class="pa-product-select"
        :options="availableApps"
        :multiple="false"
        :show-labels="false"
        track-by="id"
        label="internal_name"
        placeholder="Select a Product"
        :disabled="!canWriteProductAssociations"
      />
    </div>
    <div
      v-if="!hideCategories"
      class="product-association-column pa-main-column categories-column"
    >
      <hlt-multiselect
        v-if="categories"
        ref="multiselect2"
        v-model="selectedCategory"
        class="pa-category-select"
        :options="categories"
        :multiple="false"
        :show-labels="false"
        track-by="id"
        label="name"
        placeholder="Select a Category"
        :disabled="!canWriteProductAssociations"
      />
    </div>
    <div
      v-if="!hideCategories"
      class="product-association-column category-id-column"
    >
    {{ category ? category.id : null }}
    </div>
    <div
      v-if="!hideCategories"
      class="product-association-column category-id-column"
    >
      <a :href="webLink" target="_blank">web</a>
    </div>
    <div
      v-if="!hideOrder"
      class="product-association-column order-column"
    >
      <input
        v-model="association.order"
        type="number"
        name="order"
        class="order"
        :disabled="!canWriteProductAssociations"
      >
    </div>
    <div
      v-if="!hideVisibility"
      class="product-association-column visibility-column"
    >
      <hlt-toggle
        :value="association.visibility"
        v-model="association.visibility"
        style-type="default"
        sync
        :disabled="!canWriteProductAssociations"
      />
    </div>
    <div
      v-if="canDeleteProductAssociations"
      class="product-association-column"
    >
      <a
        v-if="selectedApp"
        class="hlt-button warning"
        @click="removeAssociation()"
      >Remove</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import HLTMultiselect from '../../shared/VueMultiSelect'
import HltToggle from '../shared/page_components/Toggle'
export default {
  components: {
    'hlt-multiselect': HLTMultiselect,
    HltToggle
  },
  props: {
    association: {
      type: Object,
      required: true
    },
    asset: {
      type: Object,
      required: true
    },
    hideCategories: {
      type: Boolean,
      default: false
    },
    hideOrder: {
      type: Boolean,
      default: false
    },
    hideVisibility: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      categories: [],
      app: null,
      category: null
    }
  },
  computed: {
    webLink() {
      return this.association.web_deep_link;
    },
    canWriteProductAssociations () {
      if (this.$router.isRoute('topic')) {
        return this.currentUser.permissions.can_write_topics_product_associations
      } else if (this.$router.isRoute('attachment')) {
        return this.currentUser.permissions.can_write_attachments_product_associations
      } else if (this.$router.isRoute('item')) {
        return this.currentUser.permissions.can_write_items_product_associations
      } else if (this.$router.isRoute('grouping')) {
        return this.currentUser.permissions.can_write_groupings_product_associations
      } else if (this.$router.isRoute('learning_module')) {
        return this.currentUser.permissions.can_write_learning_modules_product_associations
      } else if (this.$router.isRoute('deck')) {
        return this.currentUser.permissions.can_write_decks_product_associations
      } else {
        return false
      }
    },
    canDeleteProductAssociations () {
      if (this.$router.isRoute('topic')) {
        return this.currentUser.permissions.can_delete_topics_product_associations
      } else if (this.$router.isRoute('attachment')) {
        return this.currentUser.permissions.can_delete_attachments_product_associations
      } else if (this.$router.isRoute('item')) {
        return this.currentUser.permissions.can_delete_items_product_associations
      } else if (this.$router.isRoute('grouping')) {
        return this.currentUser.permissions.can_delete_groupings_product_associations
      } else if (this.$router.isRoute('learning_module')) {
        return this.currentUser.permissions.can_delete_learning_modules_product_associations
      } else if (this.$router.isRoute('deck')) {
        return this.currentUser.permissions.can_delete_decks_product_associations
      } else {
        return false
      }
    },
    selectedApp: {
      get () {
        return this.app
      },
      set (optionValue) {
        this.app = optionValue
        if (optionValue) {
          this.association.app_id = optionValue.id
        }
        this.categories = []
        this.category = null
        this.updateCategories()
      }
    },
    selectedCategory: {
      get () {
        return this.category
      },
      set (optionValue) {
        this.category = optionValue
        if (optionValue !== null) {
          this.association.category_id = optionValue.id
        } else {
          this.association.category_id = null
        }
      }
    },
    availableApps () {
      var as = this.currentUser.apps
      var asIds = this.currentUser.apps.map(x => x.id)
      const results = as.concat(this.apps
        .filter(k => asIds.indexOf(k.id) === -1)
        .filter(l => l.internal_name !== undefined))
        .sort((a, b) => {
          return (a.internal_name > b.internal_name) - (a.internal_name < b.internal_name)
        })
      return results
    },
    ...mapGetters([
      'apps',
      'currentUser',
      'appCategories'
    ])
  },
  watch: {
    selectedApp (val, oldVal) {
      this.$emit('association-changed')
    },
    selectedCategory (val, oldVal) {
      this.$emit('association-changed')
    }
  },
  mounted () {
    if (this.association) {
      this.app = this.availableApps.filter(x => x.id === this.association.app_id)[0]
      this.updateCategories()
    }
  },
  methods: {
    ...mapMutations([
      'setAppCategories'
    ]),
    removeAssociation () {
      var index = this.asset.product_associations.indexOf(this.association)
      this.association._destroy = true
      this.asset.product_associations.splice(index, 1, this.association)
    },
    setCategory () {
      if (this.association.category_id != null) {
        this.category = this.categories
          .filter(c => c.id === this.association.category_id)[0]
      }
    },
    updateCategories () {
      if (this.app && this.app.id != null) {
        if (typeof this.appCategories[this.app.id] === 'undefined') {
          axios.get(railsRoutes.api_path({ type: 'categories' }), {
            params: { app_id: this.app.id }
          }).then(response => {
            this.categories = response.data.records
            this.categories.sort((a, b) => {
              return (a.name > b.name) - (a.name < b.name)
            })
            this.setAppCategories({ appId: this.app.id, categories: this.categories })
            this.setCategory()
          }).catch(error => {
            console.error(error, 'Category Update')
          })
        } else {
          this.categories = this.appCategories[this.app.id]
          this.setCategory()
        }
      } else {
        return false
      }
    }

  }
}
</script>
<style lang="scss">
.product-association-row {
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  margin: 0;
  justify-content: space-between;
  .pa-product-select, .pa-category-select {
    .multiselect {
      margin: 0;
    }

    .multiselect__content-wrapper {
      width: fit-content;
      min-width: 200px;
    }

    .multiselect__option {
      width: 100%;
    }

    .multiselect__single {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
    }
  }
  .visibility-column {
    display: flex;
    align-items: center;
  }
}
</style>
