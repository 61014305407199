<template>
  <div class="modal-container">
    <div class="agreement-text">
      By clicking here, you accept full responsibility for your actions and attest that you are of currently sober mind and body capable of making such an important decision.
      This is a change you can't undo. Once you overwrite the live version, the updated version will be sent out to users.
    </div>
    <div class="date-row">
      <input
        class="checkbox"
        type="checkbox"
        v-model="updateRevisedDate"
        @change="checkedUpdateRevised"
        v-if="!hideCheckbox" /> I want to update the revised date to:
      <div
        v-if="!showDatePicker"
        class="selected-date"
        @click="showDatePicker = true"
      >
        {{ $date(selectedDate.selectedDate).format('LL') }}
      </div>
      <FunctionalCalendar
        v-show="showDatePicker"
        ref="Calendar"
        v-model="selectedDate"
        :configs="calendarConfigs"
        @dayClicked="dayClicked"
      />

    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      calendarData: {},
      showingDate: new Date(),
      showDatePicker: false,
      calendarConfigs: {
        sundayStart: true,
        dateFormat: 'yyyy-mm-dd',
        isDatePicker: true,
        isDateRange: false,
        isMultiple: false,
        calendarsCount: 1,
        withTimePicker: false,
        changeMonthFunction: true,
        disabledDates: ['afterToday']
      },
      updateRevisedDate: false
    }
  },
  computed: {
    selectedDate: {
      get () {
        return this.calendarData
      },
      set (value) {
        this.calendarData = value
        this.showingDate = value.dateRange.start

        if (this.calendarData.selectedDate) {
          this.$emit('revisedChanged', this.calendarData.selectedDate)
        }
      }
    }
  },
  methods: {
    dayClicked () {
      this.showDatePicker = false
    },
    checkedUpdateRevised() {
      if (this.calendarData.selectedDate) {
        this.$emit('checkedUpdateRevised', this.updateRevisedDate, this.calendarData.selectedDate)
      } else {
        this.$emit('checkedUpdateRevised', this.updateRevisedDate, this.$date().format('LL'))
      }
    }
  },
  mounted () {
    if (this.hideCheckbox) {
      this.updateRevisedDate = true
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  font-weight: 500;
}
.date-row {
  margin: 20px;
  color: $space-black;

  input {
    margin-right: 10px;
  }
  .selected-date {
    display: inline;
    margin-left: 10px;
    cursor: pointer;
    color: $eastern-blue;
  }
}
.agreement-text {
  padding: 20px;
  color: $space-gray;
}
.cancel-button {
  background: $space-gray;
  border: 1px solid $slate-gray;
}
</style>