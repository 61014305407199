<template>
  <div class="product-association-container">
    <div class="flex">
      <div class="label-heading">
        Product Associations
      </div>
    </div>
    <div class="flex">
      <div
        class="product-association-column pa-main-column"
       :class="{'wide-column': hideCategories}"
      >
        <label>Product</label>
      </div>
      <div
        v-if="!hideCategories"
        class="product-association-column pa-main-column categories-column"
      >
        <label>Category</label>
      </div>
      <div
        v-if="!hideCategories"
        class="product-association-column category-id-column"
      >
        <label>cid</label>
      </div>
      <div
        v-if="!hideCategories"
        class="product-association-column category-id-column"
      >
        <label>Link</label>
      </div>
      <div
        v-if="!hideOrder"
        class="product-association-column order-column"
      >
        <label>Order</label>
      </div>
      <div
        v-if="!hideVisibility"
        class="product-association-column visibility-column"
      >
        <label>Visible</label>
      </div>
      <div class="product-association-column" />
    </div>
    <product-association-fields
      v-for="association in productAssociations"
      :key="association.id"
      :association="association"
      :asset="asset"
      @association-changed="checkAddingNew()"
      :hide-categories="hideCategories"
      :hide-order="hideOrder"
      :hide-visibility="hideVisibility"
    />
  </div>
</template>

<script>
import ProductAssociationFields from './ProductAssociationFields'
export default {
  components: { ProductAssociationFields },
  props: {
    asset: {
      type: Object,
      required: true
    },
    hideCategories: {
      type: Boolean,
      default: false
    },
    hideOrder: {
      type: Boolean,
      default: false
    },
    hideVisibility: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    productAssociations () {
      if (this.asset && this.asset.product_associations) {
        return this.asset.product_associations.filter(k => k._destroy !== true)
      } else {
        return []
      }
    }
  },
  created () {
    this.checkAddingNew()
  },
  updated () {
    this.checkAddingNew()
  },
  methods: {
    checkAddingNew () {
      if (this.asset.product_associations) {
        var last = this._.last(this.asset.product_associations)
        if (!last || (last.app_id != null) || (last.category_id != null)) {
          this.newAssociation()
        }
      }
    },
    newAssociation () {
      if (this.asset.product_associations) {
        this.asset.product_associations.push({ id: null, app_id: null, category_id: null })
      }
    }
  }
}
</script>
<style lang="scss" >
.product-association-container {
  width: 100%;
  padding: 20px 0;
  .sync-product-associations {
    .sync-button {
      background-color: $blue-4;
      border: none;
      color: $white-2;
      font-size: 0.75em;
      padding: 10px 15px;
      &:hover {
        background-color: $blue-3;
        border: none;
        color: $white-2;
        box-shadow: 0;
      }
    }
  }

  .product-association-column {
    font-weight: normal;
    max-height: 50px;
    width: 18%;
    box-sizing: border-box;
    padding: 0;
    flex: 1;
    margin-right: 2%;
    .order {
      border-radius: 5px;
      line-height: 35px;
      height: 35px;
    }
    label {
      font-weight: bold;
      margin-bottom: .25rem;
    }
    input {
      height: 45px;
      margin-top: 2px;
    }
    &:last-of-type {
      text-align: right;
      margin: 0;
    }
  }
  .pa-main-column {
    flex: 2;
    &.categories-column {
      flex: 3;
    }
  }
  .wide-column {
    flex: 6;
  }
}
</style>
