<template>
  <div class="tag-metadata-container" v-if="option">
    <h6 class="h6">Metadata</h6>
    <div class="metadata-row">
      <div class="metadata-label">
        ID:
      </div>
      <div class="metadata-value">
        {{option.id}}
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">
        Name:
      </div>
      <div class="metadata-value">
        {{option.name}}
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">
        Type:
      </div>
      <div class="metadata-value">
        {{option.tag_type}}
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">
        Vertical:
      </div>
      <div class="metadata-value">
        {{verticalName}}
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">
        Visible?
      </div>
      <div class="metadata-value tag-visibility">
        {{tagVisibility}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'verticals'
    ]),
    tagVisibility () {
      return (this.option && this.option.visibility) ? 'Yes' : 'No'
    },
    verticalName () {
      if (this.option.vertical_id && this.verticals.length > 0) {
        return this.verticals.find(x => x.id === this.option.vertical_id).name
      } else {
        return 'N/A'
      }
    }
  },
  async mounted () {
    await this.getVerticals()
  },
  methods: {
    ...mapActions([
      'getVerticals'
    ])
  }
}
</script>

<style lang="scss">
.tag-metadata-container {
  width: 200px;
  .metadata-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .metadata-label {
      flex: 2;
    }
    .metadata-value {
      flex: 5;
    }
  }
}
</style>
