<template>
  <modal
    v-if="asset && assetType"
    name="delete-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal"
    width="45%"
  >
    <div
      v-if="asset.id != undefined"
      class="vue-modal-inner"
    >
      <div class="vue-modal-topbar">
        <h4>Are you sure you want to delete this {{ assetType }}?</h4>
      </div>
      <div class="modal-container">
        <div>
          <slot name="name" />
        </div>
        <div>
          <slot name="body" />
        </div>
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="hlt-button warning delete-button modal-button"
          @click="destroy()"
        >
          Delete
        </button>
        <button
          class="hlt-button default modal-button"
          @click="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DeleteConfirmation',
  computed: {
    ...mapGetters([
      'asset',
      'assetType'
    ])
  },
  methods: {
    destroy () {
      this.setDeleteAsset({ value: true })
      if (this.$modal) {
        this.$modal.hide('delete-modal')
      }
    },
    cancel () {
      this.$modal.hide('delete-modal')
    },
    ...mapMutations(['setDeleteAsset'])
  }
}
</script>

<style lang="scss" scoped>
.v--model {
  z-index: 999;
}
.modal-buttons button {
  margin-left: 10px;
}
</style>
