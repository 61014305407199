<template>
  <div class="hlt-tag-multiselect">
    <multiselect
      ref="multiTags"
      v-bind="$attrs"
      :track-by="trackBy"
      :label="label"
      v-on="$listeners"
      @open="openHandler"
    >
      <template
        slot="tag"
        slot-scope="{ remove, option }"
      >
      <v-popover
        offset="6"
      >

        <button
          class="metadata"
        >
          <span class="option__desc multiselect__tag">
            <span class="option__title">
            {{ option.name | truncate(30) }}
            <i
              aria-hidden="true"
              tabindex="1"
              @click.prevent="removeOption(remove, option)"
              @keypress.enter.prevent="removeOption(remove, option)"
              @mousedown.prevent="removeOption(remove, option)"
              class="multiselect__tag-icon">
            </i>
            </span>
          </span>
        </button>
        <template slot="popover">
          <tag-metadata :option="option"/>
        </template>
      </v-popover>

      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">
            {{ props.option.name }}
          </span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TagMetadata from './TagMetadata'
export default {
  components: {
    Multiselect,
    TagMetadata
  },
  props: {
    trackBy: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    openHandler () {
      this.$emit('open')
      const that = this.$refs.multiTags
      let value = JSON.parse(JSON.stringify(that.value))
      if (that && that.trackBy) {
        if (typeof that.value === 'object' && that.trackBy) {
          value = that.multiple ? that.value[0] : that.value
          if (!value) {
            return
          }
          that.pointer = that.filteredOptions.findIndex(opt => opt && (opt[that.trackBy] === value[that.trackBy]))
        } else {
          that.pointer = that.filteredOptions.indexOf(value)
        }
      }
      if (that.$refs.list) {
        that.$nextTick(() => {
          that.$refs.list.scrollTop = that.pointerPosition
        })
      }
    },
    removeOption (remove, option) {
      remove(option)
    }
  }
}
</script>
<style lang="scss">
  .hlt-tag-multiselect {
    input.multiselect__input {
      border: 0;
      box-shadow: none;
      background: none;
    }
    .multiselect__tag {
      background: $sky-blue;
    }
    .multiselect__tag-icon:hover {
      background: transparent;
    }
    .multiselect__tag-icon:after {
      color: $white-2;
    }
    .multiselect__tags {
      border: 1px solid $space-gray;
    }
    .multiselect__option--highlight {
      background: $sky-blue;
    }
    .metadata {
      cursor: pointer;
    }
  }
  .tooltip {
    max-width: 100%;
  }
  .tooltip.popover {
    $color: rgba($sky-blue, .9);
    .popover-inner {
      background: $steel-gray;
      color: $white-2;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba($space-gray, .1);
      font-size: 0.75rem;
    }
    .tooltip-arrow {
      border-color: $steel-gray;
    }
  }
  .v-popover {
    width: auto;
    display: inline-flex;
  }
</style>
