<template>
  <div
    v-if="assetAssociations"
    class="asset-associations-summary"
  >
    <label>Asset Associations</label>
    <div
      v-if="assetAssociations.length == 0"
      class="no-asset-associations"
    >
      <p>None</p>
    </div>
    <table
      v-if="assetAssociations.length > 0"
      class="asset-associations-table"
    >
      <thead>
        <tr>
          <th>QID</th>
          <th>CONTEXT</th>
          <th>BUTTON</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(association, index) in assetAssociations"
          :key="'asset-associations-table-' + index"
          :association="association"
          class="asset-association"
        >
          <td class="qid">
            <router-link
              :to="qidRoute({type: association.parent_asset_b_type, id: association.parent_asset_b_id})"
              target="_blank"
            >
              {{ getQid({asset_id: association.parent_asset_b_id, asset_type: association.parent_asset_b_type}) }}
            </router-link>
          </td>
          <td class="human-context">
            {{ humanContext(association.asset_a_context) }}
          </td>
          <td class="button-text">
            {{ association.button_text }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import qid from '../../../mixins/qid'

export default {
  mixins: [
    qid
  ],
  props: [
    'assetAssociations'
  ],
  methods: {
    ...mapActions([
      'copyToClipboard'
    ]),
    humanContext (context) {
      switch (context) {
        case 'question':
          return 'Stem'
        case 'rationale':
          return 'Explanation'
        default:
          return context
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.topic-table {
  &.asset-associations-summary {
    label {
      font-weight: 600;
      color: $grey-1;
    }
    p {
      padding: 10px 0;
      margin-bottom: 10px;
    }
  }
}
.asset-associations-summary .no-asset-associations {
  margin-top: 0px;
}
.asset-associations-summary {
  margin-bottom: 10px;

  .no-asset-associations {
    margin: 0;
    color: $grey-3;
    font-size: .8em;
  }
  .asset-associations-table {
    background-color: white;
    text-align: left;
    border-spacing: 0px;
    border-collapse: separate;
    border: 1px solid $grey-3;
    border-radius: 6px;

    th, tr, td {
      border: none;
      border-right: 0px;

      margin: 0px;
      padding: 0px;
    }
    tr {
      border-bottom: 1px solid $grey-3;
      td {
        border-left: 1px solid $grey-3;
        padding: 5px;
        padding-left: 10px;
        font-size: .7em;
      }
      td:first-of-type {
        border-left: none;
      }

    }

    tr:first-of-type {
      th {
        font-size: .7em;
        padding: 5px;
        padding-left: 10px;
        color: $blue-2;
        border-bottom: 1px solid $grey-3;
        border-left: 1px solid $grey-3;
      }
      th:first-of-type {
        border-left: none;
        border-top-left-radius: 6px;
      }
      th:last-of-type {
        border-top-right-radius: 6px;
      }
    }

    tr:last-of-type {
      td:first-of-type {
        border-bottom-left-radius: 6px;
      }
      td:last-of-type {
        border-bottom-right-radius: 6px;
      }
    }

    .qid {
      a {
        color: $blue-2;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
