<template>
  <toggle
    class="hlt-toggle"
    :on-label="labels.checked"
    :off-label="labels.unchecked"
    :style="styles"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    styleType: {
      type: String,
      default: 'default'
    },
    sync: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default: () => ({
        checked: 'On',
        unchecked: 'Off'
      })
    },
    color: {
      type: Object,
      default: () => ({
        checked: '#4fb9de',
        unchecked: '#99a3af',
        disabled: '#CCCCCC'
      })
    }
  },
  data () {
    return {
      toggleSize: {
        small: {
          height: 20,
          font: 11,
          width: 55
        },
        default: {
          height: 25,
          font: 13,
          width: 65
        },
        medium: {
          height: 27,
          font: 11,
          width: 115
        },
        large: {
          height: 27,
          font: 13,
          width: 120
        }
      },
    }
  },
  computed: {
    toggleOptions () {
      return this.toggleSize[this.styleType]
    },
    styles () {
      return `
        --toggle-width: ${this.toggleOptions.width}px;
        --toggle-bg-on: ${this.color.checked};
        --toggle-border-on: ${this.color.checked};
        --toggle-bg-off: ${this.color.unchecked};
        --toggle-border-off: ${this.color.unchecked};

        --toggle-bg-on-disabled: ${this.color.disabled};
        --toggle-bg-off-disabled: ${this.color.disabled};
        --toggle-border-on-disabled: ${this.color.disabled};
        --toggle-border-off-disabled: ${this.color.disabled};
        --toggle-font-size: ${this.toggleOptions.font}px;

      `
    }
  },
}
</script>
<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss">
.hlt-toggle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.03em;
  font-weight: 700;
  --toggle-text-on: #ffffff;
  --toggle-text-off: #ffffff;
}
</style>
